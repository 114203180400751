/**
 * This file contains main window commands that should appear everywhere. This
 * is useful when a command applies in many contexts. You can control when the
 * command is available to run by implementing `isApplicable`.
 *
 * To add another command, define a new subclass and append the
 * class to the default export at the bottom of the file.
 */
import {type ColorModeWithAuto, getClientTheme, setClientMode, setClientTheme} from '../../color-modes'
import {type CommandPalette, StaticItemsPage} from '@github-ui/command-palette'
import {MainWindowCommand, MainWindowGlobalCommand} from '../main-window-command'
import type {MainWindowCommandItem} from '../items/main-window-command-item'
import {SwitchTabSize} from './everywhere/switch-tab-size'
import {verifiedFetch} from '@github-ui/verified-fetch'

class OpenInDotDev extends MainWindowCommand {
  override title = 'Open in github.dev editor'
  override icon = 'codespaces-color-fg-muted'
  override priority = 10

  override isApplicable() {
    return this.fetchLink() instanceof HTMLAnchorElement
  }

  fetchLink() {
    return document.querySelector<HTMLAnchorElement>('.js-github-dev-shortcut')
  }

  override run() {
    this.fetchLink()?.click()
  }
}

export class SwitchTheme extends MainWindowGlobalCommand {
  override title = 'Switch theme'
  override icon = 'paintbrush-color-fg-muted'
  override priority = 9
  override dismissAfterRun = false

  override run(commandPalette: CommandPalette) {
    commandPalette.pushPage(new StaticItemsPage(this.title, 'switch-theme-page-1', this.pageItems), true)
  }

  get pageItems(): MainWindowCommandItem[] {
    return [SwitchToDark, SwitchToLight, SwitchToDarkDimmed, SwitchToDarkHighContrast, SwitchToAuto].map(command =>
      command.item(),
    )
  }

  override select(commandPalette: CommandPalette) {
    this.run(commandPalette)
  }
}

class SwitchToDark extends MainWindowCommand {
  override title = 'Default dark'
  override icon = 'moon-color-fg-muted'
  mode: ColorModeWithAuto = 'dark'
  theme = 'dark'
  override group = ''

  applyTheme() {
    this.loadStyles(this.theme)

    if (this.mode !== 'auto') {
      setClientTheme(this.theme, this.mode)
    }
    setClientMode(this.mode)
  }

  override async run() {
    // Set color theme in browser immediately to provide instantaneous response.
    this.applyTheme()
    this.saveSettings(this.mode, this.lightTheme, this.darkTheme)
  }

  /**
   * Save color mode settings to server. If successful, the server settings are
   * applied to the browser to ensure what the user sees is matches the server.
   */
  async saveSettings(colorMode: ColorModeWithAuto = this.mode, lightTheme?: string, darkTheme?: string) {
    const formData = new FormData()

    formData.set('color_mode', colorMode)
    if (lightTheme) formData.set('light_theme', lightTheme)
    if (darkTheme) formData.set('dark_theme', darkTheme)

    const response = await verifiedFetch('/settings/appearance/color_mode', {
      method: 'PUT',
      body: formData,
    })

    const settings = (await response.json()) as {
      color_mode: ColorModeWithAuto
      light_theme: string
      dark_theme: string
    }

    // Load theme CSS
    this.loadStyles(settings.light_theme)
    this.loadStyles(settings.dark_theme)

    // Set theme CSS classes
    setClientTheme(settings.light_theme, 'light')
    setClientTheme(settings.dark_theme, 'dark')

    // Set color mode classes
    setClientMode(settings.color_mode)
  }

  loadStyles(theme: string) {
    const linkTag = document.querySelector<HTMLLinkElement>(`link[data-color-theme='${theme}']`)

    if (linkTag && !linkTag.hasAttribute('href') && linkTag.hasAttribute('data-href')) {
      linkTag.setAttribute('href', linkTag.getAttribute('data-href')!)
    }
  }

  get darkTheme(): string | undefined {
    if (this.mode === 'dark') {
      return this.theme
    } else {
      return getClientTheme('dark')!
    }
  }

  get lightTheme(): string | undefined {
    if (this.mode === 'light') {
      return this.theme
    } else {
      return getClientTheme('light')!
    }
  }
}

class SwitchToDarkHighContrast extends SwitchToDark {
  override title = 'Switch theme to dark high contrast'
  override theme = 'dark_high_contrast'
}

class SwitchToDarkDimmed extends SwitchToDark {
  override title = 'Dark dimmed'
  override theme = 'dark_dimmed'
}

class SwitchToLight extends SwitchToDark {
  override title = 'Default light'
  override icon = 'sun-color-fg-muted'
  override mode: ColorModeWithAuto = 'light'
  override theme = 'light'
}

class SwitchToAuto extends SwitchToDark {
  override title = 'Sync with system settings'
  override icon = 'sun-color-fg-muted'
  override mode: ColorModeWithAuto = 'auto'

  override get darkTheme() {
    return undefined
  }

  override get lightTheme() {
    return undefined
  }
}

class UpdateSubscription extends MainWindowCommand {
  constructor() {
    super()
    const isSubscribeCommand = this.isSubscribe()
    this.title = `${isSubscribeCommand ? 'Subscribe' : 'Unsubscribe'}`
    this.icon = `${isSubscribeCommand ? 'bell' : 'bell-slash'}-color-fg-muted`
  }

  override isApplicable() {
    return this.fetchButton() instanceof HTMLButtonElement && this.fetchButton()?.disabled === false
  }

  isSubscribe() {
    return this.fetchButton()?.textContent?.trim() === 'Subscribe'
  }

  fetchButton() {
    return document.querySelector<HTMLButtonElement>('[data-thread-subscribe-button]')
  }

  override run() {
    this.fetchButton()?.click()
  }
}

export default [OpenInDotDev, SwitchTabSize, SwitchTheme, UpdateSubscription]

import type {CommandPaletteHelpElement} from '../command-palette-help-element'
import type {Query} from '../query'
import type {ServerDefinedItem} from '../items/server-defined-item'
import {ServerDefinedProvider} from './server-defined-provider'

/**
 * Provides links to search a repository, an organization/user, or all of GitHub.
 */
export class HelpProvider extends ServerDefinedProvider {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  override enabledFor(_query: Query): boolean {
    return true
  }

  override clearCache(): void {
    // nothing
  }

  override get hasCommands(): boolean {
    return false
  }

  override get debounce() {
    return 0
  }

  override async fetch(query: Query, isEmpty = false) {
    if (query.mode !== '?' && !isEmpty) {
      return {results: []} as {results: ServerDefinedItem[]}
    }

    const helpElements = Array.from(this.element.querySelectorAll<CommandPaletteHelpElement>('command-palette-help'))
    const helpItems = helpElements
      .filter(helpElement => helpElement.show(query))
      .map((helpElement, index) => helpElement.toItem(index))

    return {results: helpItems} as {results: ServerDefinedItem[]}
  }
}

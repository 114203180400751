import type {CommandPaletteScopeElement, Scope} from './command-palette-scope-element'
import {controller, target} from '@github/catalyst'
import {isPlatformMetaKey} from './command-palette-element'

/**
 * <command-palette-input> manages top bar of the command palette, including the
 * search scope tokens, text field, typeahead, and clear button.
 *
 * == Attributes
 * - value: Sets the text field value
 * - placeholder: Sets the placeholder for the text field
 * - typeahead: Sets the typeahead value. If typeahead value starts with the text
 *              fields input (case insensitive match), it is displayed as an autocomplete.
 *              Otherwise, it is displayed after the text fields input, preceded by an en dash.
 * - scope: Sets the text in the scope UI element.
 *
 * == Events
 * - command-palette-input: Fires when the user enters text
 * - command-palette-descope: Fires when the user hits [Backspace] on an empty text field
 * - command-palette-cleared: Fires when the user clicks the clear button
 */

@controller
export class CommandPaletteInputElement extends HTMLElement {
  @target typeaheadPlaceholder: HTMLElement
  @target typeaheadText: HTMLElement
  @target mirror: HTMLElement
  @target clearButton: HTMLElement

  static tagName = 'command-palette-input'

  static get observedAttributes() {
    return ['input-value', 'typeahead', 'scope']
  }

  scopeElement: CommandPaletteScopeElement
  defaultScope: Scope
  input: HTMLInputElement
  overlayInput: HTMLInputElement
  searchIcon: HTMLElement
  spinner: HTMLElement
  typeaheadValue: string
  setupComplete = false
  connected = false

  setup() {
    this.classList.add('d-flex', 'flex-items-center', 'flex-nowrap', 'py-1', 'pl-3', 'pr-2', 'border-bottom')

    this.input = this.querySelector('input.js-input')!
    this.overlayInput = this.querySelector('input.js-overlay-input')!
    this.scopeElement = this.querySelector<CommandPaletteScopeElement>('command-palette-scope')!
    this.searchIcon = this.querySelector('.js-search-icon')!
    this.spinner = this.querySelector('.js-spinner')!

    this.defaultScope = this.scope

    if (this.hasAttribute('autofocus')) {
      this.input.focus()
    }

    // Do we need to emit events at the start?
    if (this.inputValue.length !== 0) {
      this._dispatchEvent('command-palette-input')
    }

    this.setupComplete = true

    const event = new CustomEvent('command-palette-input-ready', {
      bubbles: true,
      cancelable: true,
    })

    this.dispatchEvent(event)
  }

  connectedCallback() {
    if (!this.setupComplete) {
      this.setup()
    }

    this.inputValue = this.getAttribute('input-value') || ''
    this.typeahead = this.getAttribute('typeahead') || ''
    this.placeholder = this.getAttribute('placeholder') || ''

    this.connected = true
  }

  attributeChangedCallback(attributeName: string, _oldValue: string, newValue: string) {
    if (!this.input) return

    if (attributeName === 'typeahead') {
      this.typeahead = newValue
    } else if (attributeName === 'input-value') {
      this.inputValue = newValue
      this._dispatchEvent('command-palette-input')
    }
  }

  override focus() {
    this.input.focus()
  }

  setRemovedTokenAndSelect(text: string) {
    if (text) {
      this.inputValue = text
    }

    this.focus()
    this.input.select()
  }

  get scope() {
    return this.scopeElement.scope
  }

  set scope(newScope: Scope) {
    this.scopeElement.scope = newScope
    this.clearButton.hidden = !this.hasSomethingToClear()
  }

  hasScope() {
    return this.scopeElement.hasScope()
  }

  clearScope() {
    return this.scopeElement.clearScope()
  }

  removeToken() {
    return this.scopeElement.removeToken()
  }

  get placeholder() {
    return this.input.getAttribute('placeholder') || ''
  }

  set placeholder(value: string) {
    this.input.setAttribute('placeholder', value)
  }

  get typeaheadPlaceholderText(): string {
    return this.typeaheadPlaceholder.textContent || ''
  }

  set typeaheadPlaceholderText(value: string) {
    this.typeaheadPlaceholder.textContent = value
  }

  get inputValue() {
    return this.input?.value || ''
  }

  set inputValue(value) {
    this.input.value = value
    this.typeahead = value
    this.resetPlaceholder()
    this.onInput()
  }

  get overlay() {
    return this.overlayInput.value
  }

  set overlay(value: string) {
    this.overlayInput.value = value
  }

  get typeahead() {
    return this.typeaheadValue
  }

  set typeahead(value) {
    this.typeaheadValue = this.overlay + value
    this.mirror.textContent = this.inputValue

    if (value === '') {
      this.typeaheadText.textContent = ''
    } else {
      // when the typeahead is set, the placholder needs to be hidden
      // so that the typeahead can be seen even when there's no query value
      this.placeholder = ''
      this.typeaheadPlaceholderText = ''

      if (this.valueStartsWithTypeahead) {
        const offset = this.inputValue.length - (this.overlay ? 1 : 0)
        this.typeaheadText.textContent = value.substring(offset)
      } else {
        this.typeaheadText.textContent = ` \u2013 ${value}`
      }
    }
  }

  showModePlaceholder(placeholderText = '') {
    this.typeaheadPlaceholderText = placeholderText
  }

  get valueStartsWithTypeahead() {
    return this.typeaheadValue.toLowerCase().startsWith(this.inputValue.toLowerCase())
  }

  get isCursorAtEnd() {
    return this.inputValue.length === this.input.selectionStart
  }

  set loading(isLoading: boolean) {
    this.spinner.hidden = !isLoading
    this.searchIcon.hidden = isLoading
  }

  resetPlaceholder() {
    const valueWithoutPlaceholder = this.inputValue.replace(this.overlay, '')

    if (valueWithoutPlaceholder && this.overlay) {
      this.typeaheadPlaceholderText = ''
    }

    this.placeholder = this.getAttribute('placeholder') || ''
  }

  /* eslint-disable-next-line custom-elements/no-method-prefixed-with-on */
  onInput() {
    this.resetPlaceholder()

    // Don't send a input event unless setup/connection is complete.
    // This way we can modify the input during the `this.connectedCallback()` without consequence.
    if (!this.connected) return
    this.clearButton.hidden = !this.hasSomethingToClear()
    this._dispatchEvent('command-palette-input')
  }

  /* eslint-disable-next-line custom-elements/no-method-prefixed-with-on */
  onClear(event?: Event) {
    // eslint-disable-next-line @github-ui/ui-commands/no-manual-shortcut-logic
    if (event instanceof KeyboardEvent && event.key !== 'Enter') return

    this.inputValue = ''
    this.input.focus()
    this._dispatchEvent('command-palette-cleared')
  }

  /* eslint-disable-next-line custom-elements/no-method-prefixed-with-on */
  onKeydown(event: KeyboardEvent) {
    /* eslint eslint-comments/no-use: off */
    /* eslint-disable @github-ui/ui-commands/no-manual-shortcut-logic */
    // There was something to "select" and user hit proper key
    if (this.isSelectKeystroke(event.key)) {
      this._dispatchEvent('command-palette-select')

      event.stopImmediatePropagation()
      event.preventDefault()
    }

    if (this.hasSomethingToClear() && isPlatformMetaKey(event) && event.key === 'Backspace') {
      this.onClear()
      return
    }

    // User hit backspace on empty input
    if (this.input.selectionStart === 0 && this.input.selectionEnd === 0 && event.key === 'Backspace') {
      this._dispatchEvent('command-palette-descope')

      event.stopImmediatePropagation()
      event.preventDefault()
      return
    }
    /* eslint-enable @github-ui/ui-commands/no-manual-shortcut-logic */
  }

  hasSomethingToClear() {
    return this.scopeElement.hasScope() || this.inputValue.length > 0
  }

  isSelectKeystroke(key: string) {
    return key === 'Tab' || (key === 'ArrowRight' && this.isCursorAtEnd)
  }

  textSelected() {
    return this.input.selectionStart !== this.input.selectionEnd
  }

  /**
   * Emit CustomEvent of given event name with detail containing input and typeahead value.
   *
   * @param eventName
   * @returns void
   */
  _dispatchEvent(eventName: string) {
    const event = new CustomEvent(eventName, {
      cancelable: true,
      detail: {
        typeahead: this.typeahead,
        value: this.inputValue,
      },
    })

    return this.dispatchEvent(event)
  }
}

import {PrefetchedProvider} from './prefetched-provider'
import type {Query} from '../query'

export class CommandsProvider extends PrefetchedProvider {
  override enabledFor(query: Query): boolean {
    const modelessCommandScopeTypes = ['discussion', 'issue', 'pull_request']

    if (query.mode === '>') {
      return true
    } else if (modelessCommandScopeTypes.includes(query.scope.type)) {
      return true
    } else {
      return false
    }
  }
}

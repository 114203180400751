import {MainWindowCommand, MainWindowGlobalCommand} from '../../main-window-command'
import type CommandPalette from '../../command-palette-element'
import type {MainWindowCommandItem} from '../../items/main-window-command-item'
import {StaticItemsPage} from '@github-ui/command-palette'
import {verifiedFetch} from '@github-ui/verified-fetch'

/**
 * Allow users to configure their tab size rendering preference.
 * Valid values are 2, 3, 4, 6, 8, 10, and 12
 *
 */
class TabSizeTwo extends MainWindowCommand {
  /** overrides MainWindowCommand */
  override title = '2 spaces'
  override iconType = 'none'

  /** used when persisting the new setting and when updating the DOM.
   * override this for each value.*/
  tabSize = '2'
  override group = ''

  override async run(commandPalette: CommandPalette) {
    this.updateTabSize()
    this.saveSettings(commandPalette)
  }

  /**
   * Updates all the `[data-tab-size]` attributes we can find in the DOM to reflect
   * tabSize
   */
  updateTabSize() {
    const targets = document.querySelectorAll('[data-tab-size]')
    for (const target of targets) {
      target.setAttribute('data-tab-size', this.tabSize)
    }
  }

  async saveSettings(commandPalette: CommandPalette) {
    const formData = new FormData()

    formData.set('tab_size_rendering_preference', this.tabSize)

    // eslint-disable-next-line i18n-text/no-en
    const errorMessage = 'Failed to save tab size preference'

    try {
      const response = await verifiedFetch('/settings/appearance/tab_size', {
        method: 'PUT',
        body: formData,
      })
      if (response.ok) {
        // eslint-disable-next-line i18n-text/no-en
        commandPalette.displayFlash('success', 'Tab size rendering updated')
      } else {
        commandPalette.displayFlash('error', errorMessage)
      }
    } catch {
      commandPalette.displayFlash('error', errorMessage)
    }
  }
}
class TabSizeThree extends TabSizeTwo {
  override title = '3 spaces'
  override tabSize = '3'
}
class TabSizeFour extends TabSizeTwo {
  override title = '4 spaces'
  override tabSize = '4'
}
class TabSizeSix extends TabSizeTwo {
  override title = '6 spaces'
  override tabSize = '6'
}
class TabSizeEight extends TabSizeTwo {
  override title = '8 spaces'
  override tabSize = '8'
}
class TabSizeTen extends TabSizeTwo {
  override title = '10 spaces'
  override tabSize = '10'
}
class TabSizeTwelve extends TabSizeTwo {
  override title = '12 spaces'
  override tabSize = '12'
}

export class SwitchTabSize extends MainWindowGlobalCommand {
  override title = 'Change tab size rendering'
  override icon = 'gear-color-fg-muted'
  override priority = 10
  override dismissAfterRun = false

  override run(commandPalette: CommandPalette) {
    commandPalette.pushPage(new StaticItemsPage(this.title, 'tab-sizes', this.pageItems), true)
  }

  get pageItems(): MainWindowCommandItem[] {
    return [TabSizeTwo, TabSizeThree, TabSizeFour, TabSizeSix, TabSizeEight, TabSizeTen, TabSizeTwelve].map(command =>
      command.item(),
    )
  }

  override select(commandPalette: CommandPalette) {
    this.run(commandPalette)
  }
}

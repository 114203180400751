/**
 * This file contains main window commands for issues.
 *
 * To add another command, define a new subclass and append the
 * class to the default export at the bottom of the file.
 */
import {MainWindowCommand} from '../main-window-command'

class DeleteDiscussion extends MainWindowCommand {
  override title = 'Delete discussion…'
  override icon = 'trash-color-fg-muted'

  get deleteButton() {
    return document.querySelector<HTMLButtonElement>('button#dialog-show-delete-discussion')
  }

  get dialogElement() {
    return document.querySelector<HTMLElement>('#delete-discussion')
  }

  override isApplicable() {
    return this.deleteButton != null
  }

  override run() {
    const deleteDiscussionsButton = this.deleteButton
    if (deleteDiscussionsButton) {
      deleteDiscussionsButton.click()
      setTimeout(() => {
        this.dialogElement?.querySelector<HTMLButtonElement>('button[type="submit"]')?.focus()
      }, 0)
      return
    }
  }
}

class EditDiscussion extends MainWindowCommand {
  override title = 'Edit discussion body'
  override icon = 'pencil-color-fg-muted'

  get editButton() {
    return document.querySelector<HTMLDetailsElement>('.js-discussions-comment-edit-button')
  }

  override isApplicable() {
    return this.editButton != null
  }

  override run() {
    this.editButton?.click()
  }
}

class TransferDiscussion extends MainWindowCommand {
  override title = 'Transfer discussion…'
  override icon = 'arrow-right-color-fg-muted'

  get transferButton() {
    return document.querySelector<HTMLButtonElement>('button#dialog-show-discussion-transfer-conversation')
  }

  get dialogElement() {
    return document.querySelector<HTMLElement>('#discussion-transfer-conversation')
  }

  override isApplicable() {
    return this.transferButton != null
  }

  override run() {
    const transferDiscussionButton = this.transferButton
    const transferDiscussionDialog = this.dialogElement

    if (transferDiscussionButton && transferDiscussionDialog) {
      transferDiscussionButton.click()
      const focusMenu = () => {
        setTimeout(() => {
          transferDiscussionDialog?.querySelector<HTMLElement>('[data-menu-button]')?.focus()
        }, 0)
      }

      transferDiscussionDialog.querySelector<HTMLElement>('include-fragment')?.addEventListener('load', focusMenu)
      focusMenu()
    }
  }
}

export default [DeleteDiscussion, TransferDiscussion, EditDiscussion]
